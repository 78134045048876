import { DateTime, Duration } from 'luxon'
import { Badge } from 'primereact/badge'
import { useInterval } from 'primereact/hooks'
// import { Knob } from 'primereact/knob';
import { Tag } from 'primereact/tag'
import React, { useState } from 'react'
import { isArrayEmpty, isObject, isUndefined } from '../helpers/core/util/ObjectUtil'
import { calculaIntervalo } from '../helpers/TournamentsHelpers'

const TimerNextBlind = ({ torneio }) => {
  const [minutes, setMinutes] = useState('')
  const [seconds, setSeconds] = useState('')
  const [currentBlindsEntryStructure, setCurrentBlindsEntryStructure] = useState(null)
  const [currentBlindsEntry, setCurrentBlindsEntry] = useState([])
  const [isMaxLateEndRegister, setIsMaxLateEndRegister] = useState(false)
  const [showTimer, setShowTimer] = useState(false)
  const [isPaused, setIsPaused] = useState(false)

  const calcTime = () => {
    const _blindsEntryStructure = currentBlindEntryStructure()
    let time = 0
    if (_blindsEntryStructure !== null) {
      time = DateTime.fromFormat(_blindsEntryStructure.timer, 'mm:ss')
      setIsMaxLateEndRegister(_blindsEntryStructure.isMaxLateEndRegister)
      setCurrentBlindsEntryStructure(_blindsEntryStructure?.blindsEntryStructure)
    }
    const _minutes = Math.floor((time % 3600000) / 60000)
    const _seconds = Math.floor((time % 60000) / 1000)
    setMinutes(_minutes)
    setSeconds(_seconds)
  }

  const currentBlindEntryStructure = () => {
    setShowTimer(false)
    let _blind = null
    let _dateNow = DateTime.now()
    const hasTournament = isObject(torneio)
    const _humanScheduledStartDate = torneio?.humanScheduledStartDate !== null ? DateTime.fromISO(torneio?.humanScheduledStartDate) : null
    const _humanLateRegEndDate = torneio?.humanLateRegEndDate !== null ? DateTime.fromISO(torneio?.humanLateRegEndDate) : null
    if (hasTournament && _humanScheduledStartDate !== null && _humanLateRegEndDate !== null) {
      const _customTournaments = torneio?.customTournaments !== null ? torneio?.customTournaments : null
      const _isStartedTournament = _dateNow.toFormat('yyyy-MM-dd') === _humanScheduledStartDate.toFormat('yyyy-MM-dd')
      const _blindsStructures = _customTournaments !== null ? _customTournaments?.blindsStructures : null
      const _blindsEntryStructure = _blindsStructures !== null ? _blindsStructures?.blindsEntryStructure : null
      const _fimRegistro = calculaIntervalo(_humanScheduledStartDate, _humanLateRegEndDate)
      setCurrentBlindsEntry(_blindsEntryStructure)
      if (_humanScheduledStartDate !== null && _dateNow > _humanScheduledStartDate && _isStartedTournament) {
        if (!isArrayEmpty(_blindsEntryStructure)) {
          const _allBlindsEntry = _blindsEntryStructure
          let _timeNow = DateTime.now().toFormat('HH:mm:ss')
          _timeNow = Duration.fromISOTime(_timeNow)
          // identifica o nivel do blind corrente;
          _blind = _allBlindsEntry?.find((blind) => {
            const _hourBlind = DateTime.fromISO(blind.hourBlind).toUnixInteger()
            const _hourNow = DateTime.now().toUnixInteger()
            let _hour = _hourNow > _hourBlind ? blind.hourBlindWithPause !== null ? blind.hourBlindWithPause : blind.hourBlind : blind.hourBlind
            _hour = DateTime.fromISO(_hour).toFormat('HH:mm:ss')
            _hour = Duration.fromISOTime(_hour)
            if (_timeNow.toMillis() < _hour.toMillis()) {
              return blind
            }
            return null
          })
        };
        if (_blind !== null && !isUndefined(_blind)) {
          const _hourBlind = DateTime.fromISO(_blind.hourBlind).toUnixInteger()
          const _hourNow = DateTime.now().toUnixInteger()
          let _hour = _hourNow > _hourBlind ? _blind.hourBlindWithPause !== null ? _blind.hourBlindWithPause : _blind.hourBlind : _blind.hourBlind
          _dateNow = DateTime.now().toFormat('HH:mm:ss')
          _dateNow = Duration.fromISOTime(_dateNow)
          _hour = DateTime.fromISO(_hour).toFormat('HH:mm:ss')
          _hour = Duration.fromISOTime(_hour)
          let _diff = _hour.minus(_dateNow)
          _diff = _diff.toFormat('mm:ss')
          setShowTimer(_fimRegistro > 0)
          let minutesMaxLate = DateTime.fromISO('00:04:59').toFormat('HH:mm:ss')
          minutesMaxLate = Duration.fromISOTime(minutesMaxLate)
          const isMaxLateEndRegister = minutesMaxLate >= _fimRegistro
          return { timer: _diff, blindsEntryStructure: _blind, torneio, isMaxLateEndRegister }
        }
      }
    }
    return null
  }

  const blindEntryBodyTemplate = () => {
    let maxLate = false
    let blindsEntry = '-'
    if (currentBlindsEntryStructure !== null) {
      maxLate = (currentBlindsEntryStructure.structureLevel === currentBlindsEntry.length || isMaxLateEndRegister) &&
                currentBlindsEntryStructure.blindsEntry !== null
      blindsEntry = currentBlindsEntryStructure.blindsEntry
    }
    return (
      <>
        <span className='font-bold text-yellow-200'>
          {!maxLate
            ? <span>{blindsEntry}</span>
            : maxLate && showTimer
              ? (<>
                <span className='p-overlay-badge'>{blindsEntry}<Badge /></span>
              </>)
              : (
                <div>
                  <Tag severity='danger' value='Closed' className='p-overlay-badge' />
                </div>
                )}
        </span>
      </>
    )
  }

  const timeBodyTemplate = () => {
    return (
      <>
        {showTimer
          ? <small>
            {!isPaused ? minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0') : <i className='pi pi-clock text-red-500' />}
            </small>
          : ''}
      </>
    )
  }

  useInterval(() => {
    calcTime()
  },
  1000, // delay (ms)
  !isPaused // condition (when)
  )

  useInterval(() => {
    const _currentMinutes = DateTime.now().minute
    if (!isUndefined(torneio?.networkDetails)) {
      const _minutePause = torneio?.networkDetails.minutesPause
      if (_currentMinutes >= _minutePause && _currentMinutes <= (_minutePause + 5)) {
        setIsPaused(true)
      } else {
        setIsPaused(false)
      }
    }
  }, 1000, true)

  return (
    <div className='flex align-items-center gap-2'>
      {blindEntryBodyTemplate()}
      {timeBodyTemplate()}
      {/* {<Knob min={0} max={maxChronometer} value={chronometer} valueTemplate={`${chronometerTemplate}`} strokeWidth={8} readOnly size={40} />} */}
    </div>
  )
}

export default TimerNextBlind
