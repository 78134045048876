import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import React, { useCallback, useState } from 'react';
import PhoneInput, { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router-dom';
import AppMesagens from '../AppMesagens';
import EnumTipoMensagem from '../helpers/core/enumerator/EnumTipoMensagem';
import MensagemLista from '../helpers/core/message/MensagemLista';
import { crypto } from '../helpers/core/util/CryptoUtil';
import { tratarExcecao } from '../helpers/core/util/ExceptionUtil';
import { useAuth } from '../hooks/useAuth';
import MyLobbyService from '../service/MyLobbyService';
import { PlayerTeamData } from './PlayerTeamData';

export const InfoComponent = ({ label, text, children, className, props, isHome = false, reta = false }) => {
    return (
        <div className={classNames('flex border-round select-none gap-2 ', reta ? 'flex-row' : 'flex-column w-full', className)} {...props}>
            <p className="font-bold m-0 w-full">{label}</p>
            {children}
            {!children && <p className={classNames('m-0 border-round w-full text-gray-200  ', isHome ? '' : 'p-inputtext px-2 p-1')}>{text}</p>}
        </div>
    );
};

export const PlayerInfoData = ({ isHome = false }) => {
    const { user, profile } = useAuth();

    const [mensagens, setMensagens] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [nicknamePokerstars, setNicknamePokerstars] = useState(user.nicknamePokerstars !== null ? user.nicknamePokerstars : '');
    const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber !== null ? user.phoneNumber : '');
    const [timeZone, setTimeZone] = useState(user.timeZone !== null ? user.timeZone : { name: null, gmt: null });

    const [passwordChangeDialog, setPasswordChangeDialog] = useState(false);

    const timeZones = Intl.supportedValuesOf('timeZone');
    const timeZoneWithGMT = timeZones.map((tz) => {
        //let gmt = dayjs().tz(tz).format('Z');
        let gmt = DateTime.local().setZone(tz).toFormat('ZZ');
        return {
            name: tz,
            gmt
        }
    });

    const navigate = useNavigate();

    const saveUser = useCallback(async (_user) => {
        MyLobbyService.patchUser(_user)
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                profile(data);
                const mensagem = new MensagemLista();
                mensagem.simpleMensagem('Dados Pessoais alterado com sucesso', EnumTipoMensagem.INFO);
                setMensagens(mensagem);
                changePasswordClean();
            })
            .catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            });
    }, [profile]);

    const changePasswordFooter = () => {
        return (
            <React.Fragment>
                <div className="flex justify-content-end flex-wrap">
                    <Button label="Cancelar" icon="pi pi-times" className="p-button-text p-button-danger" onClick={changePasswordClean} />
                    <Button label="Confirmar" icon="pi pi-check" onClick={onChangePassword} disabled={password === '' || newPassword === '' || newPassword !== confirmNewPassword} />
                </div>
            </React.Fragment>
        );
    };

    const onChangePassword = () => {
        const _user = {
            ...user,
            straights: null,
            settings: null,
            team: null,
            subscription: null,
            password: password,
            newPassword: crypto(newPassword),
            timeZone
        };
        saveUser(_user);
    };

    const onChangeProfile = () => {
        let hasPhone = {};
        if (phoneNumber !== '') {
            hasPhone = parsePhoneNumber(phoneNumber);
        }
        const _user = {
            ...user,
            straights: null,
            settings: null,
            team: null,
            subscription: null,
            nicknamePokerstars,
            phoneNumber: hasPhone?.number,
            phoneNumberRegion: hasPhone?.country,
            timeZone
        };
        saveUser(_user);
    };

    const changePasswordClean = () => {
        setPasswordChangeDialog(false);
        setNewPassword('');
        setPassword('');
        setConfirmNewPassword('');
    };

    const timeZoneItemTemplate = (option) => {
        return (
            <React.Fragment>
                <span>(GMT {option.gmt}) {option.name}</span>
            </React.Fragment>
        );
    }

    const selectedTimeZoneTemplate = (option, props) => {
        if (option) {
            return (
                <React.Fragment>
                    <span>(GMT {option.gmt}) {option.name}</span>
                </React.Fragment>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    return (
        <React.Fragment>
            {mensagens !== '' ? <AppMesagens mensagens={mensagens} position="bottom-center" /> : ''}
            <div className="w-full bg-gray-900 h-full w-screen p-4 border-round">
                <p className="font-bold text-xl">Dados Pessoais</p>
                <Divider />

                <div className="flex flex-column gap-2">
                    <div className="flex gap-2 flex-row flex-nowrap">
                        <InfoComponent isHome label="Nome" text={user.name} />
                        <InfoComponent isHome label="E-mail" text={user.email} />
                    </div>

                    <div className="flex align-items-center justify-content-between mt-2">
                        {!isHome ? (
                            <InfoComponent className="select-text" label="Seu Nickname na PokerStars">
                                <InputText value={nicknamePokerstars} onChange={(e) => setNicknamePokerstars(e.target.value)} className="w-fit" />
                            </InfoComponent>
                        ) : (
                            user.nicknamePokerstars && <InfoComponent isHome label="Nick Pokerstars" text={user.nicknamePokerstars} />
                        )}
                        {!isHome ? (
                            <InfoComponent label="Telefone">
                                <PhoneInput
                                    countrySelectProps={{ unicodeFlags: true, disabled: true }}
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                    defaultCountry="BR"
                                    international
                                    className={"input-phone-number w-fit"}
                                // className="p-inputtext p-component p-filled w-fit"
                                />
                            </InfoComponent>
                        ) : (
                            phoneNumber && <InfoComponent isHome label="Telefone" text={formatPhoneNumber(phoneNumber)} />
                        )}
                    </div>

                    <div className="flex mt-2 ">
                        {!isHome ? (
                            <InfoComponent label={'Fuso'}>
                                <Dropdown value={timeZone} options={timeZoneWithGMT} valueTemplate={selectedTimeZoneTemplate} itemTemplate={timeZoneItemTemplate}
                                    optionLabel="name" onChange={(e) => setTimeZone(e.target.value)} placeholder="Fuso horário" filter filterBy="name" showClear className="lg:w-3 sm:w-5" />
                            </InfoComponent>
                        ) : (
                            <InfoComponent isHome label="Fuso" text={user.timeZone !== null ? `${user.timeZone.name} (GMT ${user.timeZone.gmt})` : '-'} />
                        )}
                    </div>

                    {!isHome && (
                        <div className="flex justify-content-between mt-6">
                            <div className="flex gap-2">
                                <Button label="Alterar senha" icon="pi pi-key" onClick={() => setPasswordChangeDialog(true)} />
                                <Button label="Alterar plano" icon={<FontAwesomeIcon icon={regular('credit-card')} className="mr-2" />} disabled />
                            </div>

                            <Button label="Salvar" icon="pi pi-check" onClick={onChangeProfile} />
                        </div>
                    )}
                </div>
                {isHome && (!user.nicknamePokerstars || !user.phoneNumber || !user.timeZone) && (
                    <Button label="Complete seu perfil" icon="pi pi-pencil" onClick={() => { navigate('/user_profile',); }} className="mt-4" />
                )}
            </div>

            {user?.profile === 'tplayer' && <PlayerTeamData user={user} isHome={isHome} />}
            <Dialog visible={passwordChangeDialog} style={{ width: '16vw' }} onHide={changePasswordClean} showHeader={false} footer={changePasswordFooter}>
                <div className="card p-fluid mt-5">
                    <div className="formgrid grid">
                        <div className="field col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-key"></i>
                                </span>
                                <Password type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Insira sua senha atual" autoFocus feedback={false} />
                            </div>
                        </div>
                        <div className="field col-12 mt-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-lock"></i>
                                </span>
                                <Password value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="Informe a nova senha" className={newPassword === confirmNewPassword ? "" : "p-invalid"} />
                            </div>
                        </div>
                        <div className="field col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-lock"></i>
                                </span>
                                <Password value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} placeholder="Repita a nova senha" className={newPassword === confirmNewPassword ? "" : "p-invalid"} feedback={false} />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog >
        </React.Fragment>
    );
};
