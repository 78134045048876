import { InputNumber } from 'primereact/inputnumber'
import React, { useEffect, useState } from 'react'
import { isArrayEmpty, isEmpty } from '../helpers/core/util/ObjectUtil'
import { Tooltip } from 'primereact/tooltip'

const FilterNumber = (props) => {
  const [valueOne, setValueOne] = useState('')
  const [valueTwo, setValueTwo] = useState('')
  const [placeholder, setPlaceHolder] = useState([])

  useEffect(() => {
    let _placeHolder = props.placeholder
    if (!isArrayEmpty(_placeHolder) && typeof _placeHolder !== 'undefined') {
      _placeHolder = _placeHolder.split('|')
      setPlaceHolder(_placeHolder)
    }
  }, [props])

  /*const onChange = (event) => {
    let _valueOne
    let _valueTwo
    const _id = event.originalEvent.target.id
    if (_id === 'one') {
      _valueOne = event.value
      _valueOne = _valueOne > props.max ? props.max : _valueOne
      _valueOne = _valueOne === 0 ? props.min : _valueOne
      setValueOne(_valueOne)
    }
    if (_id === 'two') {
      _valueTwo = event.value
      _valueTwo = _valueTwo > props.max ? props.max : _valueTwo
      _valueTwo = _valueTwo === 0 ? props.min : _valueTwo
      setValueTwo(_valueTwo)
    }
    _valueOne = typeof _valueOne === 'undefined' ? valueOne : _valueOne
    _valueTwo = typeof _valueTwo === 'undefined' ? valueTwo : _valueTwo

    /* if (props.filterByField === 'duration') {
           _valueOne = valueToString(_valueOne);
           _valueTwo = valueToString(_valueTwo);
        } */
  //const _value = props.type === 'range' ? [_valueOne, _valueTwo] : _valueOne

  //props.handlerFilterValue(_value, props.filterByField)
  //}

  //const debouncedChange = useDebounce(onChange);

  /*const handleChange = e => {
    debouncedChange(e)
  };*/

  /* const valueToString = (value) => {
        if (value !== null && value !== '') {
            if (typeof value !== 'undefined') {
                if (value < 10) {
                    value = `0${value}`;
                } else {
                    value = `${value}`;
                }
            }
        } else {
            value = null;
        }
        return value;
    } */

  const onFilter = (event) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter' || (event.code === 'Backspace' && (isEmpty(event.target.value)))) {
      const _value = props.type === 'range' ? [valueOne, valueTwo] : valueOne;
      event.preventDefault();
      props.handlerFilterValue(_value, props.filterByField);
    }
  }

  return (
    <React.Fragment>
      <Tooltip target=".info-target-icon" />
      <form autoComplete="off">
        {props.type !== 'range' && (
          <div className='flex'>
            <InputNumber inputId='one' value={valueOne} onChange={(e) => setValueOne(e.value)} placeholder={placeholder[0]}
              locale={props.locale} minFractionDigits={props.minFractionDigits} showButtons={props.showButtons}
              min={props.min} max={props.max} onKeyDown={onFilter} inputStyle={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }} />
          </div>
        )}

        {props.type === 'range' && (
          <React.Fragment>
            <div className='flex flex-wrap gap-2 mb-2'>
              <InputNumber
                inputStyle={{ width: '104px' }}
                inputId='one'
                value={valueOne}
                onChange={(e) => setValueOne(e.value)}
                placeholder={placeholder[0]}
                locale={props.locale}
                minFractionDigits={props.minFractionDigits}
                showButtons={props.showButtons}
                min={props.min}
                useGrouping={props.useGrouping}
                onKeyDown={onFilter}
              />
              <InputNumber
                inputStyle={{ width: '104px' }}
                inputId='two'
                value={valueTwo}
                onChange={(e) => setValueTwo(e.value)}
                placeholder={placeholder[1]}
                locale={props.locale}
                minFractionDigits={props.minFractionDigits}
                showButtons={props.showButtons}
                max={props.max}
                useGrouping={props.useGrouping}
                onKeyDown={onFilter}
              />
            </div>
            <i className="info-target-icon pi pi-info-circle"
              data-pr-tooltip="Precione ENTER pra Filtrar"
              data-pr-position="bottom">
            </i>
          </React.Fragment>
        )}
      </form>
    </React.Fragment>
  )
}

export default FilterNumber
